import consumer from "./consumer"

consumer.subscriptions.create("ContactMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('Connected to Contact Messages Room!');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("Recibi el mensaje");
    //console.log("data");
    //console.log(data);
    //console.log(data.message_html);
    //getTopNotifications();
    var source = $('body').data('source'),
        escalated_messages_view = ($("#escalated_messages_view").length > 0),
        closed_escalations_view = ($("#escalated_messages_view #chat-contacts[data-filter='own_inbox_close']").length > 0);

    if (!closed_escalations_view && source == data.source) {
      if (data.unread_messages_count != undefined)
        setContactCenterNotifications(data.unread_messages_count);

      //chat contacts headers
      changeContactsHeaders(data.contact, data.phone_number);

      //contact card moved to top
      if (!escalated_messages_view) {
        var linkCode = $(".cc-sidebar li.sidebar-item.active:not(.parent) a").data('code'),
            matchWithFilter = false;

        if (linkCode != undefined)
          matchWithFilter = (linkCode.indexOf('account-') >= 0 && linkCode.replace('account-', '') == data.account_id) ||
                            (linkCode.indexOf('user-') >= 0 && linkCode.replace('user-', '') == data.user_id) ||
                            (linkCode.indexOf('program-') >= 0 && linkCode.replace('program-', '') == data.last_program_name) ||
                            (data.private_tag_ids.length >= 0 && data.private_tag_ids.includes(linkCode.replace('tag-', '')))

        if ((['', undefined].indexOf($('.js-title-cc').attr('data-entity')) >= 0 && data.private_tag_ids.length == 0) || (data.contact != '' && $(".cc-contact-card[data-contact-id='" + data.contact + "']").length > 0) || matchWithFilter) {
          if (data.contact != '')
            $(".contact-card[data-contact-id='" + data.contact + "']").remove();
          else
            $(".contact-card[data-phone-number='" + data.phone_number + "']").remove();

          if (data.outbound_msg != undefined || (data.message_intro == undefined && $("#chat-contacts").data('filter') == 'own_inbox_open')) {
            $("#chat-contacts.sms").prepend(data.contact_html);
            $('[data-toggle="tooltip"]').tooltip();
          }
        }
      }

      if (data.track_action != undefined) {
        console.log('print opted-out')
        $("#list_of_comments").append(data.track_action);
        $("#new-message #opted_out").val(true);

        var $optedOutSpan = "<span id='opted-out-span' style='vertical-align: top; color: #f98e57; font-size: .77rem;'><i class='fas fa-ban' data-toggle='tooltip' title='Opted-out'></i> <span class='hide-sm mr-1'>Opted-Out</span></span>";
        $('.last-seen').prepend($optedOutSpan);
        $('.block-outbound-link').replaceWith("<a class='dropdown-item unblock-outbound-link' data-confirm='Are you sure you want to unblock the outbound messages?' data-remote='true' rel='nofollow' data-method='post' href='/contact_center/" + data.contact + "/unblock_outbound'>Unblock Outbound</a>");
      }
      //if (data.options_send != undefined) $(".controls.js-options-send").html(data.options_send);


      //messages
      prepareMessageAddition(data.contact, data.phone_number);

      var commentsList = null,
          messagesSection = null,
          comment = null;
      if (data.contact != '') {
        commentsList = $("#list_of_comments[data-contact-id='" + data.contact + "']");
        messagesSection = $("#chat-messages[data-contact-id='" + data.contact + "']");
      } else {
        commentsList = $("#list_of_comments[data-phone-number='" + data.phone_number + "']");
        messagesSection = $("#chat-messages[data-phone-number='" + data.phone_number + "']");
      }

      if (commentsList.length > 0) {
        comment = $(".comment-card[data-comment-id='" + data.message_id + "']");
        if (comment.length > 0) {
          comment.replaceWith(data.message_html);
        } else {
          commentsList.append(data.message_html);
        }

        if (commentsList.data('inbdOptions') == false)
          commentsList.find('.comment-card').last().find('.inbound-menu').remove();

        var objDiv = document.getElementById("messages-list");
        if (objDiv)
          objDiv.scrollTop = objDiv.scrollHeight;

        checkAppearedComments();
        refreshMsgTime();
      }

      //close_contact_center?
      if (data.outbound_msg != undefined) {
        if ( ($("#chat-contacts").data('filter') == 'own_inbox_open' && data.open_contact_center == false) ||
             ($("#chat-contacts").data('filter') == 'own_inbox_close' && data.open_contact_center == true) ) {
          $(".cc-contact-card[data-contact-id='" + data.contact + "']").remove();

          //Show an alert and the message intro
          var userId = $('#chat-messages').data('msgu').replace('mu', ''),
              action = data.open_contact_center == true ? 'opened' : 'closed';
          if (userId != data.user_id && messagesSection.length > 0) {
            messagesSection.html(data.message_intro);
            alert(data.user_name + ' ' + action + ' this conversation')
          }
        }

        if (data.message_id != undefined) {
          comment = $(".comment-card[data-comment-id='" + data.message_id + "']");
          if (comment.length > 0) {
            setTimeout(function() {
              $.getScript("/messages/" + data.message_id + "/check_delivery");
            }, 4000);
          }
        }
      } else if ($("#chat-contacts").data('filter') == 'own_inbox_close' && messagesSection.length > 0)
        messagesSection.html($("#messages-intro-container").html());

      $(".menu-option[data-message-type='sms'] .unread-messages-count").removeClass('d-none');
      var qMessages = $(".menu-option[data-message-type='sms'] .q-unread-messages").text().trim();
      qMessages = qMessages != '' ? parseInt(qMessages) + 1 : 1;
      $(".menu-option[data-message-type='sms'] .q-unread-messages").text(qMessages);
      $(".menu-option[data-message-type='all'] .unread-messages-count").removeClass('d-none');

      if (data.account_id != undefined) {
        //$.getScript("/contact_center/filter_contacts?message_type=sms&filter=own_inbox_open&refresh_sidebar=true");
        var $newUpdates = $('#new-updated');
        if ($newUpdates.length > 0)
          $newUpdates.text(parseInt($newUpdates.text()) + 1);
        else
          $('.sidebar-notification').html('<a class="btn btn-white btn-sm btn-content-centered mr-3 mt-1 py-0 js-refresh-sidebar"><i class="fe fe-refresh-cw" style="color: #5e99ea;"></i> &nbsp;&nbsp;<span class="f-12"><span id="new-updated">1</span> new updates</span></a>').slideDown("slow");
      }
    }
  }
});
